import "./founders_list.css";

import Splide from "@splidejs/splide";

document.addEventListener(
  "turbolinks:load",
  () => {
    const container = document.getElementById("founders_list");

    if (container && window.innerWidth < 992) {
      new Splide(`#founders_list`, {
        gap: "30px",
        arrowPath:
          "M12.3525 19.5L11.8525 19.5L11.8525 20.5L12.3525 20.5L12.3525 19.5ZM30.5334 20L27.6467 17.1132L24.7599 20L27.6467 22.8868L30.5334 20ZM12.3525 20.5L27.6467 20.5L27.6467 19.5L12.3525 19.5L12.3525 20.5Z",
        speed: 650,
        mediaQuery: "min",
        perPage: 1,
        breakpoints: {
          600: {
            perPage: 2
          }
        }
      }).mount();
    }
  },
  false
);
