/* eslint no-console:0 */
import $ from "jquery";
import Turbolinks from "turbolinks";
import "turbolinks-animate";
import Rails from "rails-ujs";
import "init";

window.jQuery = $;

Turbolinks.start();
Rails.start();

document.addEventListener("turbolinks:load", () => {
  window.TurbolinksAnimate.init({
    animation: "fadein",
    duration: "0.6s",
    reversedDisappearing: true
  });
});
