import "./menu.css";

document.addEventListener(
  "turbolinks:load",
  () => {
    const burgerMenu = document.getElementById("menu_burger");
    const container = document.getElementById("menu_container");

    burgerMenu.addEventListener("click", () => {
      burgerMenu.classList.toggle("menu_burger_opened");
      container.classList.toggle("menu_container_opened");
    });

    document.addEventListener("scroll", () => {
      if (window.scrollY > 600) {
        container.classList.add("menu_container_fixed");
      } else {
        container.classList.remove("menu_container_fixed");
      }
    });
  },
  false
);
