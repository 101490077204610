import "./gallery.css";
import "./slider.css";

import Splide from "@splidejs/splide";

document.addEventListener(
  "turbolinks:load",
  () => {
    const containers = document.querySelectorAll("#gallery");

    if (containers) {
      containers.forEach((container, index) => {
        container.setAttribute("id", `gallery-${index}`);
        const isPartial = container.getAttribute("data-partial");
        let padding = window.innerWidth >= 992 ? 120 : 32;
        if (isPartial) {
          padding = {
            left: window.innerWidth >= 992 ? 120 : 32,
            right: "30vw"
          };
        }

        new Splide(`#gallery-${index}`, {
          padding,
          gap: window.innerWidth >= 992 ? "60px" : "30px",
          arrowPath:
            "M12.3525 19.5L11.8525 19.5L11.8525 20.5L12.3525 20.5L12.3525 19.5ZM30.5334 20L27.6467 17.1132L24.7599 20L27.6467 22.8868L30.5334 20ZM12.3525 20.5L27.6467 20.5L27.6467 19.5L12.3525 19.5L12.3525 20.5Z",
          speed: 650
        }).mount();
      });
    }
  },
  false
);
