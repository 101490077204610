/* eslint-disable no-new */
/* eslint-disable no-undef */
import "./map.css";

// TODO: вот здесь локейшн задавать

document.addEventListener(
  "turbolinks:load",
  () => {
    // https://codesandbox.io/embed/4lqx5y?fontsize=14&hidenavigation=1&theme=dark&initialpath=vanilla.html&module=vanilla.html&moduleview=1

    const container = document.getElementById("map");
    async function main() {
      await ymaps3.ready;
      const {
        YMap,
        YMapDefaultSchemeLayer,
        YMapControls,
        YMapMarker,
        YMapDefaultFeaturesLayer
      } = ymaps3;
      const { YMapZoomControl } = await ymaps3.import(
        "@yandex/ymaps3-controls@0.0.1"
      );

      const location = [
        container.getAttribute("data-lat"),
        container.getAttribute("data-lon")
      ];

      const map = new YMap(
        container,
        {
          location: {
            center: location,
            zoom: container.getAttribute("data-zoom")
          }
        },
        [
          new YMapDefaultSchemeLayer(),
          new YMapControls({ position: "right" }, [new YMapZoomControl({})])
        ]
      );

      // если нужно добавить точку - раскоментить
      const content = document.createElement("div");
      content.innerHTML = `
        <svg
          width="40"
          height="64"
          viewBox="0 0 58 93"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M29.9994 87.4033C29.998 87.4033 29.997 87.4018 29.9977 87.4005L53.9642 38.0191C54.9384 35.2463 55.4634 32.2635 55.4634 29.1599C55.4634 14.4623 43.6155 2.55029 28.9968 2.55029C14.3782 2.55029 2.53027 14.4559 2.53027 29.1535C2.53027 32.8295 3.27038 36.3274 4.61142 39.5137L30.0011 87.4005C30.0018 87.4018 30.0009 87.4033 29.9994 87.4033Z"
            fill="black"
            stroke="white"
            stroke-width="3"
            stroke-miterlimit="10"
          />
          <path
            d="M28.8767 41.708C36.4612 41.708 42.6097 35.5262 42.6097 27.9007C42.6097 20.2752 36.4612 14.0935 28.8767 14.0935C21.2922 14.0935 15.1437 20.2752 15.1437 27.9007C15.1437 35.5262 21.2922 41.708 28.8767 41.708Z"
            fill="white"
            stroke="black"
            stroke-width="3"
            stroke-miterlimit="10"
          />
        </svg>
      `;
      content.classList.add("map_marker");
      map.addChild(new YMapDefaultFeaturesLayer({ zIndex: 1800 }));
      map.addChild(
        new YMapMarker(
          {
            coordinates: location
          },
          content
        )
      );
    }

    if (container) {
      main();
    }
  },
  false
);
