import "./index_page.css";
import "components/gallery/gallery";
import "components/restaurants_list/restaurants_list";

document.addEventListener(
  "turbolinks:load",
  () => {
    const nextArrow = document.getElementById("index_page_first_screen_arrow");

    if (nextArrow) {
      nextArrow.addEventListener("click", () => {
        const secondScreed = document.getElementById(
          "index_page_second_screen"
        );
        secondScreed.scrollIntoView({ block: "start", behavior: "smooth" });
      });
    }
  },
  false
);
