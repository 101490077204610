import "./reservation.css";
import Inputmask from "inputmask";

const phoneMask = new Inputmask({
  mask: "+[9]{0,}",
  clearMaskOnLostFocus: true,
  placeholder: "",
  greedy: true
});

const addMask = element => {
  if (window.innerWidth >= 992) {
    phoneMask.mask(element);
  } else {
    element.setAttribute("type", "number");
  }
};

const initForms = () => {
  addMask(document.getElementById("phone_input"));
  const enquiryInput = document.getElementById("phone_input_enquiry");
  if (enquiryInput) {
    addMask(enquiryInput);
  }
};

window.reInitForm = () => {
  initForms();
};

document.addEventListener(
  "turbolinks:load",
  () => {
    const shadow = document.getElementById("reservation_shadow");
    const cross = document.getElementById("reservation_cross");
    const content = document.getElementById("reservation_content");

    const prevent = event => {
      event.stopPropagation();
    };

    const close = () => {
      if (document.getElementById("reservation_success_ok")) {
        window.location.reload();
      }
      shadow.classList.remove("reservation_shadow_opened");
      document.body.style.overflow = "auto";
    };

    const open = restaurant => {
      shadow.classList.add("reservation_shadow_opened");
      document.body.style.overflow = "hidden";
      if (restaurant) {
        document.getElementById("input_restaurant").value = restaurant;
      }
    };

    shadow.addEventListener("click", close);
    cross.addEventListener("click", close);
    content.addEventListener("click", prevent);

    initForms();

    window.openReservationModal = restaurant => {
      open(restaurant);
    };
  },
  false
);
